<template>
  <div id="bd">
    <div id="ban-in">
      <div class="headerbox">
        <div class="bannerbox">
          <h2 class="fadeInRight animated">⼯作品牌</h2>
          <div class="words fadeInUp animated">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;研究院坚持以习近平新时代中国特⾊社会主义思想为指导，坚持“四个⾯向”、⽴⾜“四个服务”，按照省委省政府有关决策部署，在服务地⽅经济发展、发挥⼈才智库优势、打造学术交流平台、助⼒成果转化应⽤等⼯作上积极作为，把我省⼴⼤⼯业互联⽹⼤数据产业⼈才更加紧密地团结凝聚在党的周围，为奋⼒谱写中国式现代化江西篇章贡献智慧和⼒量。
          </div>
        </div>
      </div>
      <!--        <div class="ban-bg"></div>-->
    </div>

    <div class="card-1">
      <div class="wp">
        <div class="title">
          <h3>服务地⽅经济社会发展</h3>
        </div>

        <div class="cont">
          <h3>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;开展“智慧⼯业直通⻋”活动，⾯向全省102个省级以上⼯业园区，提供先进制造业集群、数字化转型、绿⾊⼯⼚等执⾏服务百余次,实现产业链的延伸和创新链的打通，助⼒园区⾼质量发展；提供能效优化、低碳发展的智能决策系统，为江西省内各地⽅政府提供多层次、多元化的新能源+科技服务⽅案，通过新能源和科技创新驱动跨界融合，助推省内各地⽅政府打造⼯业发展的新路径；与省⾦控集团为全省产业链上下游开发个性化、定制化的⾦融普惠服务，帮助6万余家制造业企业申报政策补贴，获取补助资⾦近亿元，切实解决企业融资难问题，服务地⽅经济社会发展。
          </h3>
          <ul>
            <li>
              <img src="./images/1-1.jpeg" alt="" />
              <p>◎叶建春省⻓调研研究院技术⽀撑的全省⼯业⼤数据运⾏平台</p>
            </li>
            <li>
              <img src="./images/1-2.jpeg" alt="" />
              <p>◎开展“智慧⼯业直通⻋”活动</p>
            </li>
            <li>
              <img src="./images/1-3.jpeg" alt="" />
              <p>◎组织专家委员会进⾏⻛⼒发电勘察</p>
            </li>
            <li>
              <img src="./images/1-4.jpeg" alt="" />
              <p>◎联合省⾦控集团开发个性化、定制化⾦融普惠服务</p>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="card-1">
      <div class="wp">
        <div class="title">
          <h3>发挥⼈才智库优势资源</h3>
        </div>
        <div class="cont cont2">
          <h3>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;实施“云智⼤数据⼈才⽀撑”⾏动，组织专家参与江西省开发区数字化转型实施⽅案、上饶市数字经济发展“⼗四五”规划、新建经济开发区产业规划⽅案等政策⽂件编撰；技术⽀持全省⼯业⼤数据平台运⾏，赋能省级汽⻋、新能源、有⾊⾦属、纺织服装、电⼦信息、⽣物医药等6⼤产业⼤脑平台建设；组建全省⾸批⼯业和信息化领域专家库，开展“揭榜挂帅”、产业集群、数字化转型、⽣态能源调研问诊超百次；推动惠企技术咨询⾛深⾛实，引领推动省内先进技术和科技产品在产业升级、政务服务、社会治理等各领域⼴泛应⽤，向省委省政府及有关业务主管单位提供提案建议⼗余篇，助⼒制造业抓住数字经济新机遇，有效发挥⼈才智库优势。
          </h3>
          <ul>
            <li>
              <img src="./images/2-1.png" alt="" />
              <p>◎江西省开发区数字化转型实施⽅案</p>
            </li>
            <li>
              <img src="./images/2-2.png" alt="" />
              <p>◎上饶市数字经济发展“⼗四五”规划</p>
            </li>
            <li>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  height: 360px;
                "
              >
                <img
                  style="width: 100%; height: 178px"
                  src="./images/2-3.png"
                  alt=""
                />
                <img
                  style="width: 100%; height: 178px"
                  src="./images/2-4.jpeg"
                  alt=""
                />
              </div>

              <p>◎开展技术咨询和调研问诊</p>
            </li>
            <li style="width: 49%">
              <img src="./images/2-5.png" alt="" />
              <p>◎江西省”双招双引“产业⼤脑</p>
            </li>
            <li style="width: 49%">
              <img src="./images/2-6.png" alt="" />
              <p>◎江西省⻘年科技⼯作者成果转化平台（⽹徕）</p>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="card-1">
      <div class="wp">
        <div class="title">
          <h3>打造学术宣贯交流平台</h3>
        </div>
        <div class="cont">
          <h3>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;组织“⾏业趋势与政策宣贯”活动，发挥专家资源优势，围绕我省“1269”⾏动计划、制造业数字化转型、DCMM贯标等⼯作，主办省级宣贯、培训与交流活动⼗余场，邀请有关主管业务处室负责同志、政策⽂件主要起草⼈及⾏业专家进⾏授课宣讲5.2万余⼈次；组建“云智宣讲团”，⾛进全省各设区市园区、⾼校、社会组织、有关领域企业等单位开展报告会，促进有关单位深化对我省最新政策⽂件精神的了解；定期举⾏⼤数据产业发展论坛，形成⾏业⼈才交流通道，推动制造业与⼯业互联⽹领域深度融合，帮助参会单位进⼀步掌握有关政策指引的正确⽅向，助⼒“⼯业强省”建设。
          </h3>
          <ul>
            <li>
              <img src="./images/3-1.jpeg" alt="" />
              <p>◎主办江西省产业现代化“1269”⾏动计划和DCMM宣贯会</p>
            </li>
            <li>
              <img src="./images/3-2.jpeg" alt="" />
              <p>◎授课全省⼯业企业亩产效益综合评价业务知识培训会</p>
            </li>
            <li>
              <img src="./images/3-3.jpeg" alt="" />
              <p>◎连续两届⽀撑江西省⻘年科技创新发展⼤会</p>
            </li>
            <li>
              <img src="./images/3-4.jpeg" alt="" />
              <p>◎主持江西省制造业数字化转型趋势解读和政策宣贯会</p>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="card-1" style="margin-bottom: 100px">
      <div class="wp">
        <div class="title">
          <h3>助⼒成果转化应⽤落地</h3>
        </div>
        <div class="cont cont4">
          <h3>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;构建“政产学研⽤⾦服”七位⼀体创新体系，打通“科技、产业、⾦融”融通发展通道,发挥纽带作⽤，强化交流协作，取得⽰范效果；积极探索我省⻘年科技创新“揭榜挂帅”制，链接产业供需双端，上线科研成果2千余项，实现各类项⽬累计签约1.6亿余元，有关⼯作成绩在“中国⻘年科技⼯作者⽇”全国活动发布，获中央有关领导⾼度肯定；搭建“江西省⻘年科技⼯作者成果转化平台(⽹徕)”，提供⼈才汇聚平台，切实解决⼯业从业⼈员和科技⼯作者在科研、技术、就医、求学、休闲等多⽅⾯的需求，让更多的科技成果从“书架”⾛向“货架”。
          </h3>
          <ul>
            <li>
              <div style="display: flex; justify-content: space-between">
                <img style="width: 49%" src="./images/4-1.jpg" alt="" />
                <img style="width: 49%" src="./images/4-2.jpg" alt="" />
              </div>

              <p>
                ◎⾃主研发的江西省⻘年科技⼯作者成果转化平台，由团省委、省科技厅、省科协、南昌⼤学、江西中医药⼤学、华润江中与研究院共同发布上线
              </p>
            </li>
            <li>
              <img style="height: 450px" src="./images/4-3.jpg" alt="" />
              <p>
                ◎研究院主导参与的⻘年科技创新“揭榜挂帅”⼯作作为江西典型经验向全国发布
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'yjyProduct',
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
#bd {
  //padding-top: 90px;
}
.title {
  color: #1890ff;
  font-family: PingFang SC, PingFang SC;
  text-align: center;
  padding: 40px 0;
  line-height: 30px;
  overflow: hidden;
  font-size: 30px;
  font-weight: bold;
}
#ban-in {
  position: relative;
  background-position: center 0;
  background-repeat: no-repeat;
  /*padding-top: 16%;*/
  padding-top: 90px;
  background-size: 100% 100%;
  height: 500px;
  background: #008cd6;
  // url(../../../assets/front/about/aboutbanner.png) center center
  //   no-repeat;
}
#ban-in .ban-bg {
  /* background: url(../../../image/front/ban_bg.png) center top no-repeat; */
  height: 54px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -30px;
  z-index: 60;
}
.headerbox {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.bannerbox {
  position: absolute;
  top: 90px;
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
}
.bannerbox h2 {
  font-size: 40px;
  color: #fff;
  letter-spacing: 10px;
  margin: 0;
  animation-name: fadeInOP;
  animation: fadeInOP 2s linear;
}
.bannerbox .words {
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  font-size: 16px;
  color: #fff;
  margin-top: 20px;
  letter-spacing: 3px;
  padding: 0 454px;
  padding-top: 40px;
  line-height: 30px;
  font-weight: 100;
  animation-name: fadeInOP;
  animation: fadeInOP 2s linear;
  font-family: PingFang SC, PingFang SC;
  font-weight: 300;
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
    -ms-transform: translateY(100px);
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInOP {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.bannerbox .words span {
  color: #fff000;
}

.card-1 {
  .cont {
    h3 {
      font-size: 18px;
      letter-spacing: 3px;
      line-height: 30px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
    }
    ul {
      display: flex;

      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 20px;
      li {
        width: 49%;
        margin-bottom: 16px;
        box-shadow: 0 0 6px rgba($color: #000000, $alpha: 0.2);
        padding: 4px;
        img {
          width: 100%;
          height: 300px;
        }
        p {
          text-align: center;
          font-size: 16px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
        }
      }
    }
  }
  .cont2 {
    ul {
      li {
        width: 30%;
        img {
          width: 100%;
          height: 360px;
        }
      }
    }
  }
  .cont4 {
    ul {
      li {
        width: 100%;
        img {
          width: 100%;
          height: 380px;
        }
      }
    }
  }
}
</style>
